import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import HomePage from '../pages/HomePage';

const Routing = () => {
    const router=createBrowserRouter([
        {
            path:"/",element:<HomePage />
        }
    ])
  return (
    <RouterProvider router={router} />
  )
}

export default Routing;