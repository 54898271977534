import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./HomePage.module.css";
import ReactPlayer from "react-player";
import axios from "axios";
import { baseURL } from "../api/api";
import dashjs from "dashjs";

const HomePage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchDataFromBackend = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${baseURL}videos`);
      setData(res?.data?.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDataFromBackend();
  }, []);
  return (
    <div className={classes.container}>
      {/* HEADER SECTION */}
      <div className={classes.header}>
        <div className={classes.logo_container}>
          <img
            src="/assets/images/concert.png"
            alt="replishment logo"
            className={classes.logo}
          />
        </div>
        {/* <div className={classes.menu_links}>
          <Link to="/" className={classes.link}>
            Home
          </Link>
        </div> */}
      </div>

      <div className={classes.sub_container}>
        {/* MAIN IMAGE CONTAINER */}

        <div className={classes.main_image_container}>
          <div className={classes.main_image}></div>
          {/* <div className={classes.main_image_details}>
            <a href="#videos" className={classes.btn}>
              Explore Videos
            </a>
          </div> */}
        </div>

        <div className={classes.videos_section} id="videos">
          {loading ? (
            <div className={classes.loading_container}>
              <span className={classes.loader}></span>
            </div>
          ) : (
            <div className={classes.heading}>
              <h3 className={classes.heading_title}>Explore Our Videos</h3>
            </div>
          )}

          <div className={classes.videos_card_grid}>
            {data?.length > 0 &&
              data?.map((dataItem, i) => {
                return (
                  <div className={classes.video_card} key={i}>
                    <div className={classes.video}>
                      <ReactPlayer
                        url={dataItem?.videoUrl}
                        controls
                        width="100%"
                        height="auto"
                        config={{
                          file: {
                            attributes: {
                              crossOrigin: "anonymous",
                            },
                          },
                          forceDASH: true, 
                          dashOptions: {
                            debug: {
                              logLevel: dashjs.Debug.LOG_LEVEL_INFO,
                            },
                          },
                        }}
                      />
                    </div>
                    <div className={classes.video_details_container}>
                      <p>{dataItem?.title}</p>
                      {/* <div className={classes.icon_container}>
                        <i
                          className={`fa-regular fa-thumbs-up ${classes.like_icon}`}
                        ></i>
                        <p></p>
                      </div> */}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
